import React from 'react';

function SwapVertical(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_3902_7249'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <rect width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3902_7249)'>
        <path
          d='M6.87216 9.48169C6.71273 9.48169 6.57916 9.42778 6.47148 9.31997C6.36379 9.21217 6.30995 9.07858 6.30995 8.91921V4.02111L4.57193 5.75912C4.4705 5.86296 4.3437 5.91488 4.19153 5.91488C4.03937 5.91488 3.90512 5.86107 3.78878 5.75347C3.67243 5.63609 3.61426 5.50191 3.61426 5.35095C3.61426 5.19999 3.67243 5.06634 3.78878 4.95L6.3907 2.34808C6.46089 2.27789 6.53524 2.22837 6.61377 2.19952C6.6923 2.17067 6.77643 2.15625 6.86618 2.15625C6.95592 2.15625 7.04117 2.17078 7.12193 2.19984C7.20271 2.22892 7.27771 2.27833 7.34693 2.34808L9.9676 4.96875C10.0791 5.08029 10.1342 5.20961 10.1328 5.35673C10.1313 5.50384 10.0724 5.63609 9.95607 5.75347C9.83973 5.86107 9.70801 5.9168 9.56089 5.92065C9.41378 5.9245 9.28205 5.86825 9.1657 5.7519L7.43491 4.02111V8.92642C7.43491 9.08375 7.38098 9.21563 7.27313 9.32205C7.16527 9.42848 7.03161 9.48169 6.87216 9.48169ZM11.1335 15.8336C11.0437 15.8336 10.9585 15.8191 10.8777 15.79C10.7969 15.761 10.7219 15.7115 10.6527 15.6418L8.03204 13.0211C7.9205 12.9096 7.86545 12.7803 7.86689 12.6332C7.86834 12.486 7.92723 12.3538 8.04357 12.2364C8.15991 12.1288 8.29163 12.0731 8.43874 12.0692C8.58586 12.0654 8.71759 12.1216 8.83394 12.238L10.5647 13.9688V9.06345C10.5647 8.90613 10.6187 8.77424 10.7265 8.66781C10.8344 8.56138 10.968 8.50817 11.1275 8.50817C11.2869 8.50817 11.4205 8.56207 11.5282 8.66989C11.6359 8.7777 11.6897 8.91129 11.6897 9.07065V13.9688L13.4277 12.2308C13.5291 12.1269 13.6559 12.075 13.8081 12.075C13.9603 12.075 14.0945 12.1288 14.2109 12.2364C14.3272 12.3538 14.3854 12.488 14.3854 12.6389C14.3854 12.7899 14.3272 12.9235 14.2109 13.0399L11.6089 15.6418C11.5388 15.712 11.4644 15.7615 11.3859 15.7903C11.3073 15.8192 11.2232 15.8336 11.1335 15.8336Z'
          fill='#484848'
        />
      </g>
    </svg>
  );
}

SwapVertical.displayName = 'SwapVertical';

export default SwapVertical;
