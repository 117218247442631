import React, { useEffect } from 'react';
import { Pagination } from '@devstart/react-bootstrap';
import { FetchMessagesProps } from '../../admin/messages/types';
import PiAngleDoubleLeft from '../../../assets/icons/pi-angle-double-left';
import PiAngleDoubleRight from '../../../assets/icons/pi-angle-double-right';

import './paginator.css';
import PiAngleLeft from '../../../assets/icons/pi-angle-left';
import PiAngleRight from '../../../assets/icons/pi-angle-right';

type PaginatorProps = {
  readonly itemsPerPage: number;
  readonly totalItems: number;
  readonly fetchMessages: (props?: FetchMessagesProps) => void;
  readonly currentPage: number;
  readonly setCurrentPage: (page: number) => void;
};
function Paginator({
  itemsPerPage,
  totalItems,
  fetchMessages,
  currentPage,
  setCurrentPage
}: PaginatorProps): JSX.Element {
  useEffect(() => {
    fetchMessages({ pageNumber: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const showEllipsis = totalPages > 3;

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(pageNumbers.length);
  };

  return (
    <Pagination>
      <Pagination.Prev
        onClick={currentPage > 1 ? handleFirstPage : null}
        disabled={currentPage === 1}
      >
        <PiAngleDoubleLeft />
      </Pagination.Prev>

      <Pagination.Prev
        onClick={currentPage > 1 ? handlePrevPage : null}
        disabled={currentPage === 1}
      >
        <PiAngleLeft />
      </Pagination.Prev>

      {pageNumbers.map((pageNumber, index) => {
        if (showEllipsis) {
          if (
            pageNumber === 1 ||
            pageNumber === currentPage ||
            pageNumber === totalPages ||
            Math.abs(currentPage - pageNumber) === 1 ||
            Math.abs(currentPage - pageNumber) === 2
          ) {
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === currentPage}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          } else if (index === 1 || index === pageNumbers.length - 2) {
            return <Pagination.Ellipsis key={pageNumber} />;
          }
        } else {
          return (
            <Pagination.Item
              key={pageNumber}
              active={pageNumber === currentPage}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
          );
        }
        return null;
      })}

      <Pagination.Next
        onClick={pageNumbers.length !== currentPage ? handleNextPage : null}
        disabled={pageNumbers.length === currentPage}
      >
        <PiAngleRight />
      </Pagination.Next>

      <Pagination.Next
        onClick={pageNumbers.length !== currentPage ? handleLastPage : null}
        disabled={pageNumbers.length === currentPage}
      >
        <PiAngleDoubleRight />
      </Pagination.Next>
    </Pagination>
  );
}

export default Paginator;
