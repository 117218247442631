import React from 'react';
import get from 'lodash/get';

import { ColumnTypes } from './types';

interface Props<T> {
  readonly item: T;
  readonly column: ColumnTypes<T>;
}

export function TableRowCell<T>({ item, column }: Props<T>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = get(item, column.key);
  return <td>{column.render ? column.render(column, item) : value}</td>;
}
