import React from 'react';

function PiAngleRight(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      {...props}
    >
      <path
        d='M8.54144 14.0407C8.45752 14.0378 8.37505 14.0179 8.29911 13.9821C8.22316 13.9463 8.15533 13.8953 8.09978 13.8323C7.98273 13.7151 7.91699 13.5563 7.91699 13.3907C7.91699 13.225 7.98273 13.0662 8.09978 12.949L10.5998 10.449L8.09978 7.94899C8.05034 7.82898 8.03949 7.69656 8.06874 7.57011C8.09798 7.44365 8.16587 7.32944 8.26298 7.24332C8.36009 7.1572 8.48161 7.10346 8.61065 7.08954C8.7397 7.07562 8.86987 7.10223 8.98311 7.16566L11.8998 10.0823C12.0168 10.1995 12.0826 10.3584 12.0826 10.524C12.0826 10.6896 12.0168 10.8485 11.8998 10.9657L8.98311 13.8323C8.92755 13.8953 8.85972 13.9463 8.78378 13.9821C8.70783 14.0179 8.62537 14.0378 8.54144 14.0407Z'
        fill='#484848'
      />
    </svg>
  );
}

PiAngleRight.displayName = 'PiAngleRight';

export default PiAngleRight;
