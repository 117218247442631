import React from 'react';

function PiAngleDoubleLeft(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      {...props}
    >
      <path
        d='M9.16651 14.042C9.08439 14.0424 9.00302 14.0264 8.92718 13.9949C8.85134 13.9634 8.78254 13.9171 8.72484 13.8587L5.83317 10.942C5.71613 10.8248 5.65039 10.6659 5.65039 10.5003C5.65039 10.3347 5.71613 10.1758 5.83317 10.0587L8.72484 7.16699C8.84231 7.09083 8.98194 7.05631 9.12136 7.06897C9.26078 7.08162 9.39191 7.14072 9.49374 7.23679C9.59557 7.33285 9.6622 7.46032 9.68295 7.59877C9.7037 7.73722 9.67737 7.87862 9.60817 8.00032L7.10817 10.5003L9.60817 13.0003C9.72522 13.1175 9.79096 13.2764 9.79096 13.442C9.79096 13.6076 9.72522 13.7665 9.60817 13.8837C9.48756 13.9926 9.32884 14.0495 9.16651 14.042Z'
        fill='#484848'
      />
      <path
        d='M13.7498 14.042C13.5891 14.0297 13.4395 13.9549 13.3332 13.8337L10.4165 10.917C10.2995 10.7998 10.2337 10.641 10.2337 10.4753C10.2337 10.3097 10.2995 10.1509 10.4165 10.0337L13.3332 7.167C13.4498 7.1113 13.5808 7.09312 13.7082 7.11498C13.8356 7.13683 13.9531 7.19764 14.0445 7.28904C14.1359 7.38043 14.1967 7.4979 14.2185 7.62529C14.2404 7.75267 14.2222 7.8837 14.1665 8.00033L11.6665 10.5003L14.1665 13.0003C14.269 13.1149 14.3257 13.2633 14.3257 13.417C14.3257 13.5707 14.269 13.7191 14.1665 13.8337C14.0602 13.9549 13.9106 14.0297 13.7498 14.042Z'
        fill='#484848'
      />
    </svg>
  );
}

PiAngleDoubleLeft.displayName = 'PiAngleDoubleLeft';

export default PiAngleDoubleLeft;
