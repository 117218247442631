import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, startOfDay, addHours, isWithinInterval } from 'date-fns';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import Button from '../../../../components/ui/Button';
import { ButtonTypes } from '../../../../components/ui/Button/button-types';
import Input from '../../../../components/ui/Input';
import Magnifier from '../../../../assets/icons/magnifier';
import Table from '../../../../components/Table';
import { ColumnTypes } from '../../../../components/Table/types';
import {
  FilterOptionsProps,
  deleteMessageById,
  getMessages
} from '../../../../utils/ajax';
import { MessageProps, User } from '../../../../redux/prop-types';
import {
  categoryOptions,
  targetGroupOptions
} from '../../../../components/admin/messages/form';
import envData from '../../../../../../config/env.json';
import { Loader } from '../../../../components/helpers';
import { userSelector } from '../../../../redux/selectors';
import { hardGoTo as navigate } from '../../../../redux/actions';
import { FetchMessagesProps } from '../../../../components/admin/messages/types';
import DeleteModal from '../../../../components/admin/messages/delete-modal';
import AdminLayout from '../../../../components/layouts/admin-layout';

import Plus2 from '../../../../assets/icons/plus2';
import SwapVertical from '../../../../assets/icons/swap-vertical';
import Delete from '../../../../assets/icons/delete';
import Pencil from '../../../../assets/icons/pencil';

import './message-dashboard.css';

const { apiLocation } = envData;

interface MessageDashboardProps {
  readonly navigate: (location: string) => void;
  readonly user: User;
}

interface TableContent {
  id: string;
  name: string;
  targetGroup: string;
  status: string;
  effectiveness: {
    effectiveDate: string;
    expirationDate: string;
  };
}

const initialFilterOptions: FilterOptionsProps = {
  pageNumber: 1,
  pageSize: 5,
  search: '',
  sort: 'title',
  order: 'ASC'
};

const getTargetGroup = (value: string): string => {
  return (
    targetGroupOptions.find(targetGroup => targetGroup.value === value)
      ?.option ?? ''
  );
};

const getCategory = (value: string): string => {
  return (
    categoryOptions.find(category => category.value === value)?.option ?? ''
  );
};

const getMessageStatus = (
  effectiveDate: Date,
  expirationDate: Date
): string => {
  const today = startOfDay(new Date());

  const start = startOfDay(effectiveDate);
  const end = startOfDay(expirationDate);

  const flag = isWithinInterval(today, { start, end });

  if (flag) {
    return 'Vigente';
  } else {
    return 'Fora de vigência';
  }
};

const fillMessageArray = (messages: MessageProps[]): TableContent[] => {
  const convertedList: TableContent[] = messages.map(message => {
    const effectiveDate = addHours(new Date(message.effectiveDate), 3);
    const expirationDate = addHours(new Date(message.expirationDate), 3);

    return {
      id: message.id ?? '',
      name: message.title,
      targetGroup: getTargetGroup(message.targetGroup),
      category: getCategory(message.category),
      status: getMessageStatus(effectiveDate, expirationDate),
      effectiveness: {
        effectiveDate: format(effectiveDate, 'dd/MM/yyyy'),
        expirationDate: format(expirationDate, 'dd/MM/yyyy')
      }
    };
  });

  return convertedList;
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  navigate
};

function MessageDashboard({
  user,
  navigate
}: MessageDashboardProps): JSX.Element {
  const [messageList, setMessageList] = useState<TableContent[]>([]);
  const [search, setSearch] = useState('');
  const [messagesCount, setMessagesCount] = useState(0);
  const [selectedOrderColumn, setSelectedOrderColumn] = useState('name');
  const [messageId, setMessageId] = useState<string | null>(null);
  const [filter, setFilter] =
    useState<FilterOptionsProps>(initialFilterOptions);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    void fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserAdmin = user.type === 'admin';

  if (isUserAdmin) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  if (!isUserAdmin) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  const handleSort = (column: string) => {
    if (selectedOrderColumn === column) {
      void fetchMessages({
        sort: column,
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      });

      return;
    }

    void fetchMessages({
      sort: column,
      order: 'ASC'
    });

    setSelectedOrderColumn(column);
  };

  const tableColumns: ColumnTypes<TableContent>[] = [
    {
      key: 'name',
      title: (
        <button key='order-by-name' onClick={() => handleSort('title')}>
          <span>Titulo da Mensagem</span>
          <SwapVertical />
        </button>
      ),
      width: 300
    },
    {
      key: 'targetGroup',
      title: (
        <button
          key='order-by-target-group'
          onClick={() => handleSort('targetGroup')}
        >
          <span>Grupo alvo</span>
          <SwapVertical />
        </button>
      ),
      width: 160
    },
    {
      key: 'category',
      title: (
        <button key='order-by-category' onClick={() => handleSort('category')}>
          <span>Categoria</span>
          <SwapVertical />
        </button>
      ),
      width: 160
    },
    {
      key: 'status',
      title: (
        <button key='order-by-status' onClick={() => handleSort('status')}>
          <span>Situação</span>
          <SwapVertical />
        </button>
      ),
      width: 160
    },
    {
      key: 'expirationDate',
      title: (
        <button
          key='order-by-expirationDate'
          onClick={() => handleSort('expirationDate')}
        >
          <span>Vigência</span>
          <SwapVertical />
        </button>
      ),
      width: 204,
      render: (_, { effectiveness: { effectiveDate, expirationDate } }) => (
        <span>
          {effectiveDate} - {expirationDate}
        </span>
      )
    },
    {
      key: 'action',
      title: (
        <span
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'block',
            paddingRight: 12
          }}
        >
          Ações
        </span>
      ),
      width: 155,
      render: (_, { id }) => (
        <div className='action-icons'>
          <div className='action-icon'>
            <Delete
              onClick={() => {
                toggleShowModal();
                setMessageId(id);
              }}
            />
          </div>

          <Link to={`/admin/messages/message-form?id=${id}`}>
            <div className='action-icon'>
              <Pencil />
            </div>
          </Link>
        </div>
      )
    }
  ];

  async function fetchMessages(props?: FetchMessagesProps) {
    setFilter({
      ...filter,
      ...props
    });

    try {
      const messages = await getMessages({
        filter: {
          ...filter,
          ...props
        }
      });
      setMessageList(fillMessageArray(messages.messageList) ?? []);
      setMessagesCount(messages.messageListSize);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }

  const handleSubmitSearch = () => {
    void fetchMessages({ search, pageNumber: 1 });
    setCurrentPage(1);
  };

  const toggleShowModal = () => {
    showDeleteModal && setMessageId(null);
    setShowDeleteModal(prev => !prev);
  };

  const handleDeleteMessage = async () => {
    if (!messageId) return;
    const result = await deleteMessageById(messageId);
    const count = result.data.result.count;
    if (count > 0) {
      void fetchMessages();
    }
  };

  return (
    <AdminLayout title={'DEVstart | Message Dashboard'}>
      <div className='admin-dashboard-container'>
        <div className='dashboard-header'>
          <h1> {t('notice-board.created-messages')}</h1>

          <div className='search-area'>
            <Input
              placeholder={t('notice-board.message-name') ?? ''}
              insideIcon={<Magnifier width={20} height={20} />}
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  handleSubmitSearch();
                }
              }}
            />
          </div>

          <Button
            buttonType={ButtonTypes.Primary}
            onClick={() => navigate('/admin/messages/message-form')}
          >
            <Plus2 />
            {t('notice-board.create-new-message')}
          </Button>
        </div>

        <Table
          data={messageList}
          dataCount={messagesCount}
          columns={tableColumns}
          fetchMessages={fetchMessages}
          showFooter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <DeleteModal
          deleteMessage={handleDeleteMessage}
          onHide={toggleShowModal}
          show={showDeleteModal}
        />
      </div>
    </AdminLayout>
  );
}

MessageDashboard.displayName = 'MessageDashboard';

export default connect(mapStateToProps, mapDispatchToProps)(MessageDashboard);
