import React from 'react';

function PiAngleLeft(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      {...props}
    >
      <path
        d='M11.4581 14.042C11.376 14.0424 11.2946 14.0264 11.2188 13.9949C11.1429 13.9634 11.0741 13.9171 11.0164 13.8587L8.09978 10.942C7.98273 10.8248 7.91699 10.6659 7.91699 10.5003C7.91699 10.3347 7.98273 10.1758 8.09978 10.0587L11.0164 7.16699C11.1339 7.09083 11.2735 7.05631 11.413 7.06897C11.5524 7.08162 11.6835 7.14072 11.7853 7.23679C11.8872 7.33285 11.9538 7.46032 11.9746 7.59877C11.9953 7.73722 11.969 7.87862 11.8998 8.00032L9.39978 10.5003L11.8998 13.0003C12.0168 13.1175 12.0826 13.2764 12.0826 13.442C12.0826 13.6076 12.0168 13.7665 11.8998 13.8837C11.7792 13.9926 11.6204 14.0495 11.4581 14.042Z'
        fill='#484848'
      />
    </svg>
  );
}

PiAngleLeft.displayName = 'PiAngleLeft';

export default PiAngleLeft;
