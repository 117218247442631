import React from 'react';
import { ColumnTypes } from './types';

interface Props<T> {
  readonly columns: ColumnTypes<T>[];
}

export function TableHeader<T>({ columns }: Props<T>): JSX.Element {
  return (
    <tr>
      {columns.map(column => (
        <th
          key={`table-head-cell-${column.key}`}
          style={{ width: column.width }}
        >
          {column.title}
        </th>
      ))}
    </tr>
  );
}
