import React from 'react';

function PiAngleDoubleRight(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      {...props}
    >
      <path
        d='M10.8335 14.0416C10.7514 14.042 10.67 14.0259 10.5941 13.9945C10.5183 13.963 10.4495 13.9167 10.3918 13.8582C10.2748 13.741 10.209 13.5822 10.209 13.4166C10.209 13.2509 10.2748 13.0921 10.3918 12.9749L12.8918 10.4749L10.3918 7.9749C10.3334 7.85384 10.3155 7.71716 10.3409 7.58514C10.3662 7.45312 10.4335 7.3328 10.5327 7.24204C10.6319 7.15129 10.7577 7.09493 10.8914 7.08133C11.0252 7.06773 11.1597 7.09763 11.2751 7.16657L14.1918 10.0832C14.3089 10.2004 14.3746 10.3593 14.3746 10.5249C14.3746 10.6905 14.3089 10.8494 14.1918 10.9666L11.2751 13.8332C11.2196 13.8962 11.1518 13.9472 11.0758 13.983C10.9999 14.0188 10.9174 14.0387 10.8335 14.0416Z'
        fill='#484848'
      />
      <path
        d='M6.25014 14.0416C6.08935 14.0293 5.93978 13.9545 5.83347 13.8333C5.73098 13.7187 5.67432 13.5703 5.67432 13.4166C5.67432 13.2628 5.73098 13.1145 5.83347 12.9999L8.33347 10.4999L5.83347 7.99992C5.77777 7.88329 5.75959 7.75226 5.78145 7.62488C5.80331 7.49749 5.86412 7.38002 5.95551 7.28862C6.0469 7.19723 6.16438 7.13642 6.29176 7.11457C6.41915 7.09271 6.55017 7.11088 6.6668 7.16659L9.58347 10.0833C9.70051 10.2004 9.76625 10.3593 9.76625 10.5249C9.76625 10.6905 9.70051 10.8494 9.58347 10.9666L6.6668 13.8333C6.56049 13.9545 6.41092 14.0293 6.25014 14.0416Z'
        fill='#484848'
      />
    </svg>
  );
}

PiAngleDoubleRight.displayName = 'PiAngleDoubleRight';

export default PiAngleDoubleRight;
