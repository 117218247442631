import React from 'react';
import { Table } from '@devstart/react-bootstrap';
import Paginator from '../ui/Paginator';
import { FetchMessagesProps } from '../admin/messages/types';
import { TableHeader } from './table-header';
import { TableRow } from './table-row';
import { ColumnTypes } from './types';

import './table.css';

interface Props<T> {
  readonly data: T[];
  readonly dataCount: number;
  readonly columns: ColumnTypes<T>[];
  readonly showFooter?: boolean;
  readonly fetchMessages: (props?: FetchMessagesProps) => void;
  readonly currentPage: number;
  readonly setCurrentPage: (page: number) => void;
}

export function CustomTable<T>({
  data,
  dataCount,
  columns,
  showFooter = false,
  fetchMessages,
  currentPage,
  setCurrentPage
}: Props<T>): JSX.Element {
  return (
    <div className='table-container'>
      <Table
        bordered={false}
        condensed={false}
        hover
        responsive={true}
        striped={false}
        className='custom-table-style'
      >
        <thead>
          <TableHeader columns={columns} />
        </thead>
        <tbody>
          <TableRow data={data} columns={columns} />
        </tbody>
      </Table>

      {showFooter && dataCount > 0 && (
        <div className='pagination-area'>
          <Paginator
            itemsPerPage={5}
            totalItems={dataCount}
            fetchMessages={fetchMessages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
}

CustomTable.displayName = 'CustomTable';

export default CustomTable;
