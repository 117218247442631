import React from 'react';

function Plus2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33'
      height='32'
      fill='none'
      viewBox='0 0 33 32'
      {...props}
    >
      <g>
        <mask
          id='mask0_3896_2368'
          style={{ maskType: 'alpha' }}
          width='33'
          height='32'
          x='0'
          y='0'
          maskUnits='userSpaceOnUse'
        >
          <path fill='#D9D9D9' d='M0.5 0H32.5V32H0.5z'></path>
        </mask>
        <g mask='url(#mask0_3896_2368)'>
          <path
            fill='#1C1B1F'
            d='M15.5 16.999H7.833v-2H15.5V7.332h2v7.667h7.667v2H17.5v7.666h-2V17z'
          ></path>
        </g>
      </g>
    </svg>
  );
}

Plus2.displayName = 'Plus2';

export default Plus2;
