import React from 'react';
import { TableRowCell } from './table-row.cell';
import { ColumnTypes } from './types';

interface Props<T> {
  readonly data: T[];
  readonly columns: ColumnTypes<T>[];
}

export function TableRow<T>({ data, columns }: Props<T>): JSX.Element {
  return (
    <>
      {data.map((item, itemIndex) => (
        <tr key={`table-body-${itemIndex}`}>
          {columns.map((column, columnIndex) => (
            <TableRowCell
              key={`table-row-cell-${columnIndex}`}
              item={item}
              column={column}
            />
          ))}
        </tr>
      ))}
    </>
  );
}
